// Foundation
$(document).foundation();

// Scroll to top
$(".top-arrow").on('click', function () {
            $("html, body").animate({scrollTop: $("#anchor-top").offset().top}, '1000');
        });

// Hamburger Navigation
(function() {

    "use strict";

    var toggles = document.querySelectorAll(".c-hamburger");

    for (var i = toggles.length - 1; i >= 0; i--) {
        var toggle = toggles[i];
        toggleHandler(toggle);
    };

    function toggleHandler(toggle) {
        toggle.addEventListener("click", function(e) {
            e.preventDefault();

            if (this.classList.contains("is-active") === true) {
                this.classList.remove("is-active")
                document.getElementById("myNav").style.height = "0%";
            } else {
                this.classList.add("is-active");
                document.getElementById("myNav").style.height = "100%";
            }
        });
    }

})();